/*
 * Note for developers
 * This is main JS file for the site
 * Here we have all components that are included in main site
 * If you add new functionality/component that also requires in TNEW templates
 * Please add it in app-tnew.js and here as well to make it work in both, main site and TNEW templates
 */

// Add compatibility to all Browsers supporting ES5
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

const LAZYLOAD_SELECTORS = [
  '.js-lazyload',
];

const LAZYLOAD_EAGER_SELECTORS = [
  '.js-lazyload-eager',
];

// Run Application
const appModules = (async () => {
  let lazyImageInstances;
  let eagerImageInstances;

  // Blazy
  /* eslint-disable */
  if (document.querySelector(LAZYLOAD_SELECTORS.join(', ')) || document.querySelector(LAZYLOAD_EAGER_SELECTORS.join(', '))) {
    const { blazyInstance, blazyEagerInstance } = await import(
      /* webpackChunkName: "local.BlazyInstance" */
      './utils/blazyInstance'
    );

    lazyImageInstances = blazyInstance();
    eagerImageInstances = blazyEagerInstance();
  }

  // BrightcoveBackground
  if (document.querySelector('.js-brigthcove-background')) {
    const { default: BrightcoveBackground } = await import(
      /* webpackChunkName: "local.BrightcoveBackground" */
      './components/BrightcoveBackground'
    );

    BrightcoveBackground({
      selector: '.js-brigthcove-background',
    });
  }

  // CalendarSidebar
  if (document.querySelector('.js-calendar-inner')) {
    const { default: renderCalendarSidebar } = await import(
      /* webpackChunkName: "local.CalendarSidebar" */
      './react/calendar'
    );

    renderCalendarSidebar();
  }

  // Calendar
  if (document.querySelector('.js-calendar')) {
    const { default: Calendar } = await import(
      /* webpackChunkName: "local.Calendar" */
      './components/Calendar'
    );

    Calendar({
      selector: '.js-calendar',
      trigger: '.js-calendar-trigger',
      triggerMobile: '.js-calendar-trigger-mobile',
      containerInner: '.js-calendar-inner',
      mediaQuery: 1200,
    });
  }

  // CardCarousel
  if (document.querySelector('.js-card-carousel-container')) {
    const { default: CardCarousel } = await import(
      /* webpackChunkName: "local.CardCarousel" */
      './components/CardCarousel'
    );

    CardCarousel({
      selector: '.js-card-carousel-container',
      grabCursor: true,
      keyboardControl: true,
      mousewheelControl: true,
      mediaQuery: 900,
      slidesPerView: 2,
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 400px
        400: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        // when window width is >= 900px
        900: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
      },
      revalidateLazyImages: eagerImageInstances.revalidate,
    });
  }

  // CartTimerManager
  // {% set getSessionKeyEndpoint = getenv('TNEW_API') ~ 'api/session/sessionkey/' %}
  if (document.querySelector('.js-timer')) {
    const { default: CartTimerManager } = await import(
      /* webpackChunkName: "local.CartTimerManager" */
      './components/CartTimerManager'
    );

    CartTimerManager({
      selector: '.js-timer',
      cartLink: '.js-cart',
      cartLinkMobile: '.js-cart-trigger-mobile',
      userLink: '.js-login',
      cartTimerPlaceholder: '.js-timer-placeholder',
    });
  }

  // Cookies
  if (document.querySelector('.js-cookies')) {
    const { default: Cookies } = await import(
      /* webpackChunkName: "local.Cookies" */
      './components/Cookies'
    );

    Cookies({
      selector: '.js-cookies',
      cta: '.js-cookies-cta',
    });
  }

  // ConcertCard
  if (document.querySelector('.js-concert-card')) {
    const { default: ConcertCard } = await import(
      /* webpackChunkName: "local.ConcertCard" */
      './components/ConcertCard'
    );

    ConcertCard({
      selector: '.js-concert-card',
      lastPerformanceAttr: 'data-last-performance',
    });
  }

  // ContentExpandCollapse
  if (document.querySelector('.js-concert-detail')) {
    const { default: ContentExpandCollapse } = await import(
      /* webpackChunkName: "local.ContentExpandCollapse" */
      './components/ContentExpandCollapse'
    );

    ContentExpandCollapse({
      selector: '.js-concert-detail',
      trigger: '.js-expandable-trigger',
      content: '.js-expandable-content',
    });
  }

  // CreateYourOwnPackage
  if (document.querySelector('.js-create-your-own-package')) {
    const { default: CreateYourOwnPackage } = await import(
      /* webpackChunkName: "local.CreateYourOwnPackage" */
      './components/CreateYourOwnPackage'
    );

    CreateYourOwnPackage({
      selector: '.js-create-your-own-package',
    });
  }

  // Expandable
  if (document.querySelector('.js-expandable')) {
    const { default: Expandable } = await import(
      /* webpackChunkName: "local.Expandable" */
      './components/Expandable'
    );

    Expandable({
      selector: '.js-expandable',
      button: '.js-expandable-button',
      contentPanel: '.js-expandable-content',
      openClass: 'is-open',
    });
  }

  // ExpandCollapse
  if (document.querySelector('.js-expand-collapse')) {
    const { default: ExpandCollapse } = await import(
      /* webpackChunkName: "local.ExpandCollapse" */
      './components/ExpandCollapse'
    );

    ExpandCollapse({
      selector: '.js-expand-collapse',
      cards: '.js-card',
      trigger: '.js-expand-collapse-trigger',
      triggerLabel: '.js-expand-collapse-trigger-label',
      triggerLabelTextOnExpand: 'View less',
      increment: 4,
      limit: 8,
    });
  }

  // FixedNav
  if (document.querySelector('.js-hero')) {
    const { default: FixedNav } = await import(
      /* webpackChunkName: "local.FixedNav" */
      './components/FixedNav'
    );

    FixedNav({
      selector: '.js-hero',
    });
  }

  // HeroHomeCarousel
  if (document.querySelector('.js-hero-home')) {
    const { default: HeroHomeCarousel } = await import(
      /* webpackChunkName: "local.HeroHomeCarousel" */
      './components/HeroHomeCarousel'
    );

    HeroHomeCarousel({
      selector: '.js-hero-home',
      heroContainer: '.js-hero-home-container',
      buttonNext: '.js-hero-home-btn-next',
      nextPerformanceDetails: '.js-hero-home-next-performance-details',
      nextPerformanceTitle: '.js-hero-home-next-performance-title',
      contentWrapper: '.js-hero-home-content-wrapper',
      contentWrapperMobile: '.js-hero-home-content-wrapper-mobile',
      content: '.js-hero-home-content',
      contentImg: '.js-hero-home-img',
      contentImgContainer: '.js-hero-home-img-container',
      actionWrapper: '.js-hero-home-action-wrapper',
      nextPerformanceImg: '.js-hero-home-next-performance-img',
      scrollIndicator: '.js-scroll-indicator',
      title: '.js-title',
      hiddenEles: '.js-hide',
      activeSlide: '.swiper-slide-active',
      nextSlide: '.swiper-slide-next',
      previousSlide: '.swiper-slide-prev',
      mediaQuerySmall: 600,
      mediaQueryMedium: 900,
      revalidateLazyImages: lazyImageInstances.revalidate,
    });
  }

  // ImageGallery
  if (document.querySelector('.js-image-gallery')) {
    const { default: ImageGallery } = await import(
      /* webpackChunkName: "local.ImageGallery" */
      './components/ImageGallery'
    );

    ImageGallery({
      selector: '.js-image-gallery',
      galleryTop: '.js-image-gallery-top',
      galleryThumbs: '.js-image-gallery-thumbs',
      galleryButtonPrev: '.js-image-gallery-button-prev',
      galleryButtonNext: '.js-image-gallery-button-next',
      galleryScrollbar: '.js-image-gallery-scrollbar',
      galleryImgLazyload: '.js-lazyload',
    });
  }

  // Navigation
  if (document.querySelector('.js-nav')) {
    const { default: Navigation } = await import(
      /* webpackChunkName: "local.Navigation" */
      './components/Navigation'
    );

    Navigation({
      selector: '.js-nav',
      navItem: '.js-nav-item',
      toggleButton: '.js-nav-toggle',
      subNavtoggleButton: '.js-nav-sub-toggle',
      backButton: '.js-nav-back',
      headerBackground: '.js-header',
      calendarButton: '.js-calendar-trigger-mobile',
      calendarSelector: '.js-calendar',
    });
  }

  // Newsletter
  if (document.querySelector('.js-newsletter')) {
    const { default: Newsletter } = await import(
      /* webpackChunkName: "local.Newsletter" */
      './components/Newsletter'
    );

    Newsletter({
      selector: '.js-newsletter',
      cta: '.js-newsletter-cta',
    });
  }

  // NewsListing
  if (document.querySelector('.js-news-listing')) {
    const { default: renderNewsListing } = await import(
      /* webpackChunkName: "local.NewsListing" */
      './react/newsListing'
    );

    renderNewsListing();
  }

  // PackageSelector
  if (document.querySelector('.js-package-selector')) {
    const { default: PackageSelector } = await import(
      /* webpackChunkName: "local.PackageSelector" */
      './components/PackageSelector'
    );

    PackageSelector({
      selector: '.js-package-selector',
      option: '.js-package-option',
      content: '.js-package-content',
      contentInit: '.js-package-content-init',
    });
  }

  // PageScrollAnimation
  if (document.querySelector('.js-animate')) {
    const { default: PageScrollAnimation } = await import(
      /* webpackChunkName: "local.PageScrollAnimation" */
      './components/PageScrollAnimation'
    );

    PageScrollAnimation({
      selector: '.js-animate',
      eleAnimated: 'is-animated',
      eleRevealFromRight: '.js-reveal-from-right',
      mediaQuery: 900,
    });
  }

  // ScrollIndicator
  if (document.querySelector('.js-scroll-indicator')) {
    const { default: ScrollIndicator } = await import(
      /* webpackChunkName: "local.ScrollIndicator" */
      './components/ScrollIndicator'
    );

    ScrollIndicator({
      selector: '.js-scroll-indicator',
    });
  }

  // Search
  if (document.querySelector('.js-search')) {
    const { default: Search } = await import(
      /* webpackChunkName: "local.Search" */
      './components/Search'
    );

    Search({
      selector: '.js-search',
      trigger: '.js-search-trigger',
      close: '.js-search-close',
      input: '.js-search-input',
      headerBackground: '.js-header',
    });
  }
  // SearchListing
  if (document.querySelector('.js-search-listing')) {
    const { default: renderSearchListing } = await import(
      /* webpackChunkName: "local.SearchListing" */
      './react/searchListing'
    );
    renderSearchListing();
  }

  // VideoModal
  if (document.querySelector('.js-video-modal')) {
    const { default: VideoModal } = await import(
      /* webpackChunkName: "local.VideoModal" */
      './components/VideoModal'
    );

    VideoModal({
      selector: '.js-video-modal',
      modalButton: '.js-video-modal-button',
      closeButton: '.js-modal-close',
      modalInner: '.js-modal-inner',
      loader: '.js-modal-loader',
      modalOpen: 'is-visible',
      showOverlay: 'has-overlay',
      modalIdAttr: 'data-modal-id',
      videoIdAttr: 'data-video-id',
      videoTypeAttr: 'data-video-type',
    });
  }

  // Videos
  if (document.querySelector('.js-video')) {
    const { default: Video } = await import(
      /* webpackChunkName: "local.Video" */
      './components/Video'
    );

    Video({
      selector: '.js-video',
      button: '.js-video-button',
    });
  }

  // WishlistManager
  if (document.querySelector('.js-wishlist')) {
    const { default: WishlistManager } = await import(
      /* webpackChunkName: "local.WishlistManager" */
      './components/WishlistManager'
    );

    const wishlistManager = WishlistManager({
      selector: '.js-wishlist',
      wishlistPage: '.js-wishlist-page',
      navIcons: '.js-wishlist-nav',
      cookieKey: 'wishlist=',
      concertButtonClass: '.js-wishlist-button',
      clearWishlistButtonClass: '.js-wishlist-clear',
      removeFromWishlist: '.js-wishlist-remove',
      wishlistDrawer: '.js-wishlist-drawer',
      wishlistItem: '.js-wishlist-item',
      wishlistEmpty: '.js-wishlist-empty',
      openWishlist: '.js-wishlist-open',
      closeWishlist: '.js-wishlist-close',
      reactRef: 'CalCardList',
    });

    if (wishlistManager) {
      // Provide globally
      [window.WishlistManager] = wishlistManager;
    }
  }
})();

// Opt-in to Webpack hot module replacement
if (module.hot) module.hot.accept();

export default appModules;
